import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '30px 20px',

    '& h1': {
      textTransform: 'uppercase',
      fontSize: '1rem',
      fontWeight: 600,
      marginBottom: '20px',
    },
  },
  ul: {
    margin: 0,
    padding: 0,
  },
  option: {
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    borderTop: '1px solid #eee',
    cursor: 'pointer',
  },
}));

export default useStyles;
