/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ArrowBackIos,
  ShoppingCartOutlined,
  Category,
} from '@material-ui/icons';
import { itemsRequest } from '../../store/modules/data/actions';
import history from '../../services/history';

import Loading from '../../components/Loading';

import useStyles from './styles';

function Categories() {
  document
    .querySelector('meta[name="theme-color"]')
    .setAttribute('content', '#fff');

  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, categories: list = [] } = useSelector(state => state.data);

  const count = useSelector(state => {
    const { order } = state.order;
    return order ? order.items.length : 0;
  });

  const hanldeCategory = async category => {
    dispatch(itemsRequest(category));
  };

  const hanldeOrder = () => {
    if (count >= 1) history.push('/order');
  };

  return (
    <>
      <div className={classes.root}>
        <ul className={classes.list}>
          {list.map(item => (
            <li
              key={item.categoria_id}
              className={classes.item}
              onClick={() => hanldeCategory(item)}
            >
              <Category fontSize="large" />
              <span>{item.nome}</span>
            </li>
          ))}
        </ul>

        <div className={classes.footer}>
          <Link to="/">
            <ArrowBackIos fontSize="large" />
          </Link>

          <h1>categorias</h1>

          <div className={classes.cart} onClick={hanldeOrder}>
            {count >= 1 && <span>{count}</span>}
            <ShoppingCartOutlined fontSize="large" />
          </div>
        </div>
      </div>

      <Loading loading={loading} />
    </>
  );
}

export default Categories;
