import produce from 'immer';

const INITIAL_STATE = {
  product: null,
};

export default function item(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@item/SELECTED': {
        const { item: product } = action.payload;
        if (product) {
          draft.product = {
            ...product,
            key: product.key || new Date().getTime(),
            amount: product.amount || 1,
            comment: product.comment || null,
          };
        }
        break;
      }

      case '@item/CHANGE_AMOUNT': {
        const { amount = 1 } = action.payload;
        if (amount <= 0) break;
        draft.product = { ...draft.product, amount };
        break;
      }

      case '@item/SET_COMMENT': {
        const { comment = null } = action.payload;
        draft.product = { ...draft.product, comment };
        break;
      }

      case '@item/UNSET_COMMENT': {
        draft.product = { ...draft.product, comment: null };
        break;
      }

      case '@starting': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
