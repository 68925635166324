import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '30px 20px',
    width: 'max-content',
  },
  profile: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      width: '90px',
      height: '90px',
      border: '2px solid #eee',
      borderRadius: '45px',
    },
  },
  infos: {
    marginLeft: '15px',
    '& span': {
      fontSize: '12px',
      color: '#c4c4c4',
      fontWeight: 600,
    },
    '& h1': {
      margin: 0,
      padding: 0,
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  submit: {
    cursor: 'pointer',
    marginTop: '30px',
    minWidth: '230px',
    background: '#ff0000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    color: '#ffff',
    borderRadius: '5px',
    border: 'none',

    textTransform: 'uppercase',
  },
}));

export default useStyles;
