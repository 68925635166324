import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  list: {
    padding: '5px 20px 0',
  },
  item: {
    minHeight: '80px',
    padding: '20px 10px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',

    background: '#eee',
    border: '1px solid #c4c4c4',
    borderRadius: '10px',

    '& span': {
      fontSize: '0.775rem',
    },
  },
  amount: {
    minWidth: '20px',
    maxWidth: 'max-content',
  },
  name: {
    padding: '0 15px',
    flex: 1,
  },
  menu: {
    minWidth: 'max-content',
  },
  itemOptions: {
    width: '100%',
    padding: '30px 20px',

    '& h1': {
      textTransform: 'uppercase',
      fontSize: '1rem',
      fontWeight: 600,
      marginBottom: '20px',
    },
  },
  option: {
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    borderTop: '1px solid #eee',
    cursor: 'pointer',
  },
}));

export default useStyles;
