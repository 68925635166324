import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './styles';

function Summary() {
  const classes = useStyles();
  const { comanda } = useSelector(state => state.data);

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {comanda &&
          comanda.resumo &&
          comanda.resumo.map(item => (
            <div key={JSON.stringify(item)} className={classes.item}>
              <span className={classes.amount}>{item.quantidade}</span>
              <span className={classes.name}>{item.item}</span>
              <span className={classes.subtotal}>{item.formattedSubtotal}</span>
            </div>
          ))}
      </ul>

      {comanda && (
        <div className={classes.totals}>
          <div>
            <span>subtotal</span>
            <span>{comanda.formattedSubtotal}</span>
          </div>
          <div>
            <span>taxa</span>
            <span>{comanda.formattedTaxa}</span>
          </div>
          <div className={classes.total}>
            <span>total</span>
            <span>{comanda.formattedTotal}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Summary;
