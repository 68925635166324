import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  header: {
    padding: '20px 20px 35px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FF7A01',
    color: '#fff',
  },
  comanda: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-betweeen',
    flex: 1,

    '& a': {
      color: '#fff',
    },
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    fontSize: '14px',
    fontWeight: 500,

    '& h1': {
      fontSize: '16px',
      fontWeight: 600,
    },

    '& span': {
      textTransform: 'capitalize',
    },

    flex: 1,
  },
  menu: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eee',

    transform: 'translateY(-15px)',
    borderRadius: '20px 20px 0 0',
    zIndex: 1000,
    background: '#fff',

    '& li': {
      listStyle: 'none',

      fontWeight: 700,
      color: '#c4c4c4',
    },

    '& li.yes': {
      color: '#FF7A01',
    },

    '& li.yes::after': {
      transition: 'all .5s',
      content: '',
      width: '100%',
      display: 'block',
      position: 'relative',
      height: '2px',
      top: '16px',
      background: '#FF7A01',
    },
  },
}));

export default useStyles;
