/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowBackIos, Comment, PlusOne } from '@material-ui/icons';
import {
  create,
  createCancel,
  createRequest,
} from '../../store/modules/comment/actions';
import { setComment } from '../../store/modules/item/actions';
import useStyles from './styles';

import Loading from '../../components/Loading';

function Comments() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const { loading, product, comments: list, comment: comm } = useSelector(
    state => state.comment
  );

  useEffect(() => {
    setName(comm ? comm.name : '');
  }, [comm]);

  return (
    <>
      <div className={classes.root}>
        <ul className={classes.list}>
          {list &&
            list.map(comment => (
              <li
                key={comment.comment_id}
                className={classes.item}
                onClick={() => dispatch(setComment(comment))}
              >
                <Comment fontSize="large" />
                <span>{comment.name}</span>
              </li>
            ))}
        </ul>

        <div className={classes.footer}>
          <Link to="/item">
            <ArrowBackIos fontSize="large" />
          </Link>

          <h1>comentários</h1>

          <PlusOne fontSize="large" onClick={() => dispatch(create(product))} />
        </div>
      </div>

      <Drawer
        anchor="bottom"
        open={!!comm}
        onClose={() => dispatch(createCancel())}
      >
        <div className={classes.modal}>
          <div className={classes.comment}>
            <Comment fontSize="large" />
            <div className={classes.text}>
              <span>{`${name.length} / 100`}</span>
              <textarea
                name="comment"
                id="comment"
                rows="4"
                value={name}
                maxLength="100"
                onChange={event => setName(event.target.value)}
                autoFocus
              />
            </div>
          </div>
          <button
            type="button"
            className={classes.confirm}
            onClick={() => dispatch(createRequest({ ...comm, name }))}
          >
            confirmar
          </button>
        </div>
      </Drawer>

      <Loading loading={loading} />
    </>
  );
}

export default Comments;
