import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'comanda',
      storage,
      whitelist: ['auth', 'comment', 'data', 'item', 'order'],
    },
    reducers
  );

  return persistedReducer;
};
