/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { decodedServer } from '../../../store/modules/data/actions';
import useStyles from './styles';

function Server({ open, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ip, setIp] = useState('');
  const [port, setPort] = useState('');
  const { server } = useSelector(state => state.data);

  useEffect(() => {
    if (open) {
      if (server) {
        setIp(server.ip);
        setPort(server.port);
      }
    }
  }, [open, server]);

  const handleOnSubmit = async e => {
    e.preventDefault();
    dispatch(decodedServer({ ip, port }));
    handleClose(false);
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={() => handleClose(false)}>
      <div className={classes.header}>
        <h1>servidor</h1>
      </div>
      <div className={classes.root}>
        <span>Informe o Ip e a Porta de acesso</span>
        <div>
          <form onSubmit={handleOnSubmit} className={classes.sign}>
            <input
              className={classes.input}
              type="text"
              placeholder="Ip"
              value={ip}
              onChange={event => setIp(event.target.value)}
            />
            <input
              className={classes.input}
              type="text"
              placeholder="Porta"
              value={port}
              onChange={event => setPort(event.target.value)}
            />
            <input className={classes.submit} type="submit" value="SALVAR" />
          </form>
        </div>
        <span>
          Para funcionar corretamente é necessario que o servidor esteja ligado
        </span>
      </div>
    </Drawer>
  );
}

export default Server;
