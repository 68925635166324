/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { decodeServer } from '../../store/modules/data/actions';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Menu from '../../components/Menu';
import Loading from '../../components/Loading';

function Home({ match }) {
  document
    .querySelector('meta[name="theme-color"]')
    .setAttribute('content', '#FF7A01');

  const { params } = match;
  const { server = null } = params;
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.data);

  useEffect(() => {
    dispatch(decodeServer(server));
  }, [dispatch, server]);

  return (
    <>
      <Header />
      <Body />
      <Footer />
      <Menu />
      <Loading loading={loading} />
    </>
  );
}

export default Home;
