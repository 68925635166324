import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  list: {
    padding: '5px 20px 130px',
  },
  item: {
    minHeight: '80px',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',

    background: '#eee',
    border: '1px solid #c4c4c4',
    borderRadius: '10px',

    '& span': {
      fontSize: '0.775rem',
    },
  },
  amount: {
    minWidth: '20px',
    maxWidth: 'max-content',
  },
  name: {
    padding: '0 15px',
    flex: 1,
  },
  subtotal: {
    minWidth: 'max-content',
  },
  totals: {
    padding: '20px 20px 10px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    filter: 'drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25))',
    background: '#FF7A01',
    color: '#fff',
    width: '100%',
    textTransform: 'capitalize',

    '& div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontWeight: 500,
      marginBottom: '10px',
    },
  },
  total: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
}));

export default useStyles;
