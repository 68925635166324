import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
  },
  keyboard: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transform: 'translateY(-15px)',
    borderRadius: '20px 20px 0 0',
    background: '#fff',
    zIndex: 1000,
  },
  row: {
    display: 'flex',
  },
  number: {
    minWidth: '75px',
    height: '75px',
    margin: '5px',
    padding: '10px',
    borderRadius: '20px',
    background: '#fff',
    border: '2px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: '#010D1F',
    fontSize: '26px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  cancel: {
    minWidth: '75px',
    height: '75px',
    margin: '5px',
    padding: 0,
    borderRadius: '20px',
    background: '#ff0000',
    border: '1px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: '#fff',
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  enter: {
    minWidth: '75px',
    height: '75px',
    margin: '5px',
    padding: 0,
    borderRadius: '20px',
    background: '#298e4f',
    border: '1px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: '#fff',
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  comanda: {
    padding: '20px 20px 35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    borderBottom: '1px solid #eee',
    background: '#FF7A01',
    color: '#fff',
  },
}));

export default useStyles;
