/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Remove, Add, Fastfood, Comment } from '@material-ui/icons';

import history from '../../services/history';
import { changeAmount, unsetComment } from '../../store/modules/item/actions';
import { commentsRequest } from '../../store/modules/comment/actions';
import { addItem } from '../../store/modules/order/actions';

import Loading from '../../components/Loading';

import useStyles from './styles';

function Products() {
  const dispatch = useDispatch();
  const { product } = useSelector(state => state.item);

  const classes = useStyles({
    background: product.comment ? '#8822ff' : '#fff',
  });

  const { loading } = useSelector(state => state.comment);

  const hanldeClose = () => {
    if (product.order) history.push('/order');
    else history.push('/items');
  };

  const hanldeConfirm = () => {
    dispatch(addItem({ ...product }));
  };

  return (
    <>
      <div className={classes.root}>
        {product && (
          <div className={classes.content}>
            <div className={classes.title}>
              <Fastfood fontSize="large" />
              <span>{product.nome}</span>
            </div>
            <div className={classes.amount}>
              <Remove
                onClick={() => dispatch(changeAmount(product.amount - 1))}
                fontSize="large"
              />
              <span>{product.amount}</span>
              <Add
                onClick={() => dispatch(changeAmount(product.amount + 1))}
                fontSize="large"
              />
            </div>
            <div className={classes.plus}>
              <span onClick={() => dispatch(changeAmount(product.amount + 5))}>
                + 5
              </span>
              <span onClick={() => dispatch(changeAmount(product.amount + 10))}>
                + 10
              </span>
              <span onClick={() => dispatch(changeAmount(product.amount + 15))}>
                + 15
              </span>
            </div>

            {product.comment ? (
              <div
                className={classes.comment}
                onClick={() => dispatch(unsetComment())}
              >
                <Comment fontSize="large" />
                <span>{product.comment.name}</span>
              </div>
            ) : (
              <div
                className={classes.comment}
                onClick={() => dispatch(commentsRequest(product))}
              >
                <Comment fontSize="large" />
                <span>comentar</span>
              </div>
            )}
            <div className={classes.footer}>
              <div onClick={hanldeClose} className={classes.cancel}>
                cancelar
              </div>
              <span />
              <div onClick={hanldeConfirm} className={classes.confirm}>
                confirmar
              </div>
            </div>
          </div>
        )}
      </div>

      <Loading loading={loading} />
    </>
  );
}

export default Products;
