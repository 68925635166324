/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MoreVert, SyncAlt, DeleteOutline } from '@material-ui/icons';
import { comandaItemsRequest } from '../../../store/modules/data/actions';
import useStyles from './styles';

function Items() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);

  const { user } = useSelector(state => state.auth);
  const { comanda } = useSelector(state => state.data);

  useEffect(() => {
    if (comanda && !comanda.items) dispatch(comandaItemsRequest(comanda));
  }, [comanda, dispatch]);

  return (
    <>
      <div className={classes.root}>
        <ul className={classes.list}>
          {comanda &&
            comanda.items &&
            comanda.items.map(item => (
              <li
                key={item.item_id}
                className={classes.item}
                onClick={() => setProduct(item)}
              >
                <span className={classes.amount}>{item.quantidade}</span>
                <span className={classes.name}>{item.item}</span>
                {comanda &&
                  comanda.situacao === 'A' &&
                  user &&
                  (user.transferir === 'S' || user.cancelar === 'S') && (
                    <MoreVert fontSize="small" />
                  )}
              </li>
            ))}
        </ul>
      </div>

      <Drawer anchor="bottom" open={!!product} onClose={() => setProduct(null)}>
        <div className={classes.itemOptions}>
          {product && (
            <>
              <h1>{product.item}</h1>
              <ul>
                {user && user.transferir === 'S' && (
                  <li className={classes.option}>
                    <span>transferir item</span>
                    <SyncAlt />
                  </li>
                )}

                {user && user.cancelar === 'S' && (
                  <li className={classes.option}>
                    <span>remover item</span>
                    <DeleteOutline />
                  </li>
                )}
              </ul>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default Items;
