import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bookmark,
  Delete,
  Redo,
  Undo,
  MoneyOff,
  AttachMoney,
  Add,
} from '@material-ui/icons';

import { comandaAuditsRequest } from '../../../store/modules/data/actions';
import useStyles from './styles';

function Audit() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { comanda } = useSelector(state => state.data);

  useEffect(() => {
    if (comanda && !comanda.audits) dispatch(comandaAuditsRequest(comanda));
  }, [comanda, dispatch]);

  const returnIcon = icon => {
    switch (icon) {
      case 'A':
        return <Bookmark />;
      case 'B':
        return <Bookmark />;
      case 'C':
        return <Delete />;
      case 'D':
        return <Redo />;
      case 'F':
        return <Bookmark />;
      case 'O':
        return <Undo />;
      case 'P':
        return <MoneyOff />;
      case 'R':
        return <AttachMoney />;
      case 'V':
        return <Add />;
      default:
        return <Bookmark />;
    }
  };

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {comanda &&
          comanda.audits &&
          comanda.audits.map((item, index) => (
            <li key={JSON.stringify(index)} className={classes.item}>
              <div className={classes.header}>
                <span>{item.momento}</span>
                <span>{item.autor}</span>
              </div>
              <div className={classes.detail}>
                {returnIcon(item.tipo)}
                <span>{item.descricao}</span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Audit;
