/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import Loading from '../../components/Loading';
import Nunber from '../../components/Nunber';
import Summary from './Summary';
import Items from './Items';
import Orders from './Orders';
import Audit from './Audit';

import useStyles from './styles';

function Detail() {
  const classes = useStyles();
  const [menuSelect, setMenuSelect] = useState('summary');
  const { loading, comanda } = useSelector(state => state.data);

  const removeClassList = () => {
    const elements = document.getElementsByClassName('yes');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('yes');
    }
  };

  const handleMenusFunc = async e => {
    const element = e.target;
    setMenuSelect(element.id);
    await removeClassList();
    element.classList.add('yes');
  };

  function Render() {
    if (menuSelect === 'summary') {
      return <Summary />;
    }
    if (menuSelect === 'items') {
      return <Items />;
    }
    if (menuSelect === 'orders') {
      return <Orders />;
    }
    if (menuSelect === 'audit') {
      return <Audit />;
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          {comanda && (
            <div className={classes.comanda}>
              <Nunber comanda={comanda} />
              <div className={classes.fields}>
                <h1>{comanda.cliente}</h1>
                <span>{`pedidos: ${comanda.pedidos}`}</span>
                <span>{`último pedido: ${comanda.timeFomatted}`}</span>
              </div>
              <Link to="/">
                <ArrowBackIos fontSize="large" />
              </Link>
            </div>
          )}
        </div>

        <ul className={classes.menu}>
          <li className="yes" id="summary" onClick={handleMenusFunc}>
            RESUMO
          </li>
          <li id="items" onClick={handleMenusFunc}>
            ITENS
          </li>
          <li id="orders" onClick={handleMenusFunc}>
            PEDIDOS
          </li>
          <li id="audit" onClick={handleMenusFunc}>
            AUDITORIA
          </li>
        </ul>

        <Render />
      </div>

      <Loading loading={loading} />
    </>
  );
}

export default Detail;
