/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';
import { orderRequest } from '../../../store/modules/order/actions';
import useStyles from './styles';

function Send({ send, setSend }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [comanda, setComanda] = useState('');
  const { user } = useSelector(state => state.auth);
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    if (send) setComanda('');
  }, [send]);

  const number = val => {
    const value = `${comanda}`;
    if (value.trim().length === 0) {
      if (val === '0') return false;
      setComanda(val);
    } else {
      setComanda(prev => [`${prev}${val}`]);
    }
    return false;
  };

  const hanldeCancel = () => {
    const value = `${comanda}`;
    if (value.trim().length === 0) {
      setSend(false);
    } else {
      setComanda('');
    }
  };

  const hanldeEnter = async () => {
    await setSend(false);

    const { vendedor_id } = user;
    const numero = Number(comanda);
    const pedido = {
      vendedor_id,
      numero,
      items: order.items.map(item => {
        const { produto_id, amount: quantidade, comment = null } = item;
        return {
          produto_id,
          quantidade,
          comment_id: comment ? comment.comment_id : 0,
        };
      }),
    };

    dispatch(orderRequest(pedido));
  };

  return (
    <Drawer anchor="bottom" open={send} onClose={() => setSend(false)}>
      <div className={classes.root}>
        <div className={classes.comanda}>
          <span>comanda</span>
          <span className={classes.number}>
            {`${comanda}`.trim().length >= 1 ? `${comanda}` : '?'}
          </span>
        </div>
        <div className={classes.keyboard}>
          <div className={classes.row}>
            <span className={classes.number} onClick={() => number('1')}>
              1
            </span>
            <span className={classes.number} onClick={() => number('2')}>
              2
            </span>
            <span className={classes.number} onClick={() => number('3')}>
              3
            </span>
          </div>
          <div className={classes.row}>
            <span className={classes.number} onClick={() => number('4')}>
              4
            </span>
            <span className={classes.number} onClick={() => number('5')}>
              5
            </span>
            <span className={classes.number} onClick={() => number('6')}>
              6
            </span>
          </div>
          <div className={classes.row}>
            <span className={classes.number} onClick={() => number('7')}>
              7
            </span>
            <span className={classes.number} onClick={() => number('8')}>
              8
            </span>
            <span className={classes.number} onClick={() => number('9')}>
              9
            </span>
          </div>
          <div className={classes.row}>
            <span className={classes.cancel} onClick={hanldeCancel}>
              cancel
            </span>
            <span className={classes.number} onClick={() => number('0')}>
              0
            </span>
            <span className={classes.enter} onClick={hanldeEnter}>
              enter
            </span>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Send;
