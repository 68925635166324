import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  list: {
    margin: 0,
    padding: '20px 20px 80px',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    background: '#FF7A01',
    borderRadius: '10px',
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: '20px',

    '& svg': { marginRight: '20px' },
    '& span': { flex: 1 },
  },
  footer: {
    boxShadow: '0px -1px 6px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    background: '#fff',
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& h1': {
      padding: 0,
      fontWeight: 400,
      color: '#FF7A01',

      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    '& svg': {
      color: '#FF7A01',
    },
  },
  cart: {
    position: 'realtive',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      color: '#FFA827',
    },

    '& span': {
      position: 'absolute',
      top: '0.5rem',
      right: '1.2rem',
      height: '25px',
      width: '25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: ' 50%',
      background: '#298e4f',
      color: '#fff',

      fontSize: '0.75rem',
      fontWeight: 600,
    },
  },
});

export default useStyles;
