/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Replay, MoreVert } from '@material-ui/icons';
import Number from '../../../components/Nunber';
import {
  comandasRequest,
  comandaRequest,
} from '../../../store/modules/data/actions';

import Options from '../Options';
import People from '../People';

import useStyles from './styles';

function Body() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [closure, setClosure] = useState(null);
  const { user } = useSelector(state => state.auth);
  const { comandas: list = [] } = useSelector(state => state.data);

  useEffect(() => {
    dispatch(comandasRequest());
  }, [dispatch]);

  const hanldeComanda = comanda => {
    dispatch(comandaRequest(comanda));
  };

  const hanldeClosure = comanda => {
    setData(null);
    setClosure(comanda);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.infoComand}>
          <span>{`${list.length} comandas`}</span>
          <Replay
            fontSize="default"
            onClick={() => dispatch(comandasRequest())}
          />
        </div>
        <ul className={classes.ul}>
          {list.map(item => (
            <div key={item.comanda_id} className={classes.item}>
              <div
                className={classes.fields}
                onClick={() => hanldeComanda(item)}
              >
                <Number comanda={item} />
                <div className={classes.info}>
                  <h1>{item.cliente}</h1>
                  <span>{`pedidos: ${item.pedidos}`}</span>
                  <span>{`último pedido: ${item.timeFomatted}`}</span>
                </div>
                <div className={classes.price}>
                  <span>{item.formattedTotal}</span>
                </div>
              </div>

              {item.situacao === 'A' &&
                (user.fechar === 'S' ||
                  user.transferir === 'S' ||
                  user.cancelar === 'S') && (
                  <div className={classes.menu} onClick={() => setData(item)}>
                    <MoreVert fontSize="large" />
                  </div>
                )}

              {item.situacao === 'F' && user.reabrir === 'S' && (
                <div className={classes.menu} onClick={() => setData(item)}>
                  <MoreVert fontSize="large" />
                </div>
              )}
            </div>
          ))}
        </ul>
      </div>

      <Options
        comanda={data}
        setComanda={setData}
        hanldeClosure={hanldeClosure}
      />
      <People closure={closure} setClosure={setClosure} />
    </>
  );
}

export default Body;
