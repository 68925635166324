import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
  },
  footer: {
    boxShadow: '0px -1px 6px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    background: '#fff',
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& h1': {
      padding: 0,
      fontWeight: 500,
      color: '#FF7A01',

      fontSize: '1rem',
      textTransform: 'uppercase',
    },

    '& svg': {
      color: '#FF7A01',
    },
  },
}));

export default useStyles;
