import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  list: {
    margin: 0,
    padding: '20px 20px 80px',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    background: '#8822ff',
    borderRadius: '10px',
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: '20px',

    '& svg': { marginRight: '20px' },
    '& span': { flex: 1 },
  },
  footer: {
    boxShadow: '0px -1px 6px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    background: '#fff',
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& h1': {
      padding: 0,
      fontWeight: 400,
      color: '#FF7A01',

      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    '& svg': {
      color: '#FF7A01',
    },
  },
  modal: {
    margin: 0,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',

    fontSize: '1rem',
    fontWeight: 500,
  },
  comment: {
    marginBottom: '20px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#8822ff',
    borderRadius: '10px',
    color: '#fff',

    '& svg': { marginRight: '20px' },
  },
  text: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      textAlign: 'right',
      fontSize: '0.85rem',
      marginBottom: '5px',
    },

    '& textarea': {
      width: '100%',
      padding: '10px',
      filter: 'drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25))',

      textTransform: 'uppercase',
      fontSize: '0.85rem',
      border: '1px solid #eee',
      borderRadius: '8px',
    },
    '& textarea:focus': {
      border: '1px solid #eee',
      outlineColor: '#eee',
      borderRadius: '8px',
    },
  },
  confirm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 0',
    border: 'none',
    filter: 'drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25))',
    background: '#228844',
    color: '#fff',

    fontSize: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
});

export default useStyles;
