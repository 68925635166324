import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  list: {
    padding: '5px 20px 0',
  },
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',

    '& div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',

      '& span': {
        fontSize: '0.775rem',
        fontWeight: 600,
      },
    },

    '& ul': {
      width: '100%',

      '& li': {
        width: '100%',
        minHeight: '80px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',

        background: '#eee',
        border: '1px solid #c4c4c4',
        borderRadius: '10px',

        '& span': {
          fontSize: '0.775rem',
        },
      },
    },
  },
  amount: {
    minWidth: '20px',
    maxWidth: 'max-content',
  },
  name: {
    padding: '0 15px',
    flex: 1,
  },
}));

export default useStyles;
