/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Fastfood, Comment } from '@material-ui/icons';
import deletar from '../../../assets/delete.svg';
import edit from '../../../assets/edit.svg';
import { removeItem } from '../../../store/modules/order/actions';
import { selected } from '../../../store/modules/item/actions';
import history from '../../../services/history';
import useStyles from './styles';

export default function Itens() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);

  const { order } = useSelector(state => state.order);

  useEffect(() => {
    if (order && order.items.length === 0) history.push('/categories');
  }, [order]);

  const hanldeEdit = () => {
    const item = { ...product, order: true };
    dispatch(selected(item));
    setProduct(null);
  };

  const hanldeRemove = () => {
    const { key } = product;
    dispatch(removeItem(key));
    setProduct(null);
  };

  return (
    <>
      <ul className={classes.root}>
        {order &&
          order.items.map(item => (
            <li
              key={item.key}
              className={classes.item}
              onClick={() => setProduct(item)}
            >
              <div className={classes.itemInfo}>
                <Fastfood fontSize="large" />
                <span className={classes.itemInfoName}>{item.nome}</span>
                <span className={classes.itemInfoAmount}>{item.amount}</span>
              </div>
              {item.comment && (
                <div className={classes.itemComment}>
                  <Comment fontSize="small" />
                  <span>{item.comment.name}</span>
                </div>
              )}
            </li>
          ))}
      </ul>

      <Drawer anchor="bottom" open={!!product} onClose={() => setProduct(null)}>
        <div className={classes.listSettings}>
          {product && (
            <>
              <h1>{product.nome}</h1>
              <ul className={classes.ul}>
                <div className={classes.btnOptions} onClick={hanldeEdit}>
                  <span>editar</span>
                  <img src={edit} alt="editar" />
                </div>
                <div className={classes.btnOptions} onClick={hanldeRemove}>
                  <span>remover</span>
                  <img src={deletar} alt="remover" />
                </div>
              </ul>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
}
