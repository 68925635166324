import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '20px 20px 80px',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    background: '#119900',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 500,
  },
  itemInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& svg': { marginRight: '20px' },
  },
  itemInfoName: {
    flex: 1,
  },
  itemInfoAmount: {
    marginLeft: '20px',
    maxWidth: 'max-content',
  },
  itemComment: {
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: '0.5px solid #eee',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& svg': { marginRight: '20px' },
    '& span': {
      flex: 1,
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  menu: {
    maxHeight: '20px',
  },
  listSettings: {
    width: '100%',
    padding: '30px 20px',

    '& h1': {
      margin: 0,
      padding: 0,

      fontSize: '18px',
      fontWeight: 600,
      marginBottom: '20px',
    },
  },
  ul: {
    margin: 0,
    padding: 0,

    '& span': {
      textTransform: 'uppercase',
    },
  },
  btnOptions: {
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #eee',
    cursor: 'pointer',
  },
}));

export default useStyles;
