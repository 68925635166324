import { takeLatest, all } from 'redux-saga/effects';

import history from '../../../services/history';

export function selected() {
  history.push('/item');
}

export function setComment() {
  history.push('/item');
}

export default all([
  takeLatest('@item/SELECTED', selected),
  takeLatest('@item/SET_COMMENT', setComment),
]);
