import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { Subject, LockOpen, SyncAlt, HighlightOff } from '@material-ui/icons';
import { reopenComandaRequest } from '../../../store/modules/data/actions';
import useStyles from './styles';

function Options({ comanda, setComanda, hanldeClosure }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  const handleReopen = () => {
    const { comanda_id: prevenda_id } = comanda;
    const { vendedor_id } = user;
    setComanda(null);

    dispatch(
      reopenComandaRequest({
        prevenda_id,
        operador_id: 0,
        vendedor_id,
      })
    );
  };

  return (
    <Drawer anchor="bottom" open={!!comanda} onClose={() => setComanda(null)}>
      <div className={classes.root}>
        {comanda && <h1>{`COMANDA ${comanda.numero}`}</h1>}

        <ul className={classes.ul}>
          {comanda && comanda.situacao === 'A' && (
            <>
              {user && user.fechar === 'S' && (
                <div
                  className={classes.option}
                  onClick={() => hanldeClosure(comanda)}
                >
                  <span>fechar</span>
                  <Subject />
                </div>
              )}

              {user && user.transferir === 'S' && (
                <div className={classes.option}>
                  <span>transferir</span>
                  <SyncAlt />
                </div>
              )}

              {user && user.cancelar === 'S' && (
                <div className={classes.option}>
                  <span>cancelar</span>
                  <HighlightOff />
                </div>
              )}
            </>
          )}

          {comanda && comanda.situacao === 'F' && (
            <>
              {user && user.reabrir === 'S' && (
                <div className={classes.option} onClick={handleReopen}>
                  <span>reabrir</span>
                  <LockOpen />
                </div>
              )}
            </>
          )}
        </ul>
      </div>
    </Drawer>
  );
}

export default Options;
