/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowBackIos, Fastfood } from '@material-ui/icons';
import { selected } from '../../store/modules/item/actions';
import useStyles from './styles';

function Items() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { category, items: list = [] } = useSelector(state => state.data);

  const hanldeItem = product => {
    dispatch(selected(product));
  };

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {list.map(item => (
          <li
            key={item.produto_id}
            className={classes.item}
            onClick={() => hanldeItem(item)}
          >
            <Fastfood fontSize="large" />
            <span>{item.nome}</span>
          </li>
        ))}
      </ul>

      <div className={classes.footer}>
        <Link to="/categories">
          <ArrowBackIos fontSize="large" />
        </Link>
        <h1>{category && category.nome}</h1>
      </div>
    </div>
  );
}

export default Items;
