import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  list: {
    padding: '5px 20px 0',
  },
  item: {
    marginBottom: '30px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.775rem',
    fontWeight: 600,
    marginBottom: '20px',
  },
  detail: {
    width: '100%',
    minHeight: '80px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',

    background: '#eee',
    border: '1px solid #c4c4c4',
    borderRadius: '10px',

    '& span': {
      fontSize: '0.775rem',
      padding: '0 0 0 15px',
      flex: 1,
    },
  },
}));

export default useStyles;
