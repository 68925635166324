import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  content: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    background: '#0055bb',
    borderRadius: '10px',
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: '20px',

    '& svg': { marginRight: '20px' },
    '& span': { flex: 1 },
  },
  amount: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #eee',
    borderRadius: '10px',
    marginBottom: '20px',

    '& span': {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
  },
  plus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',

    '& span': {
      minWidth: '85px',
      padding: '20px',
      border: '2px solid #eee',
      borderRadius: '10px',
      fontSize: '1.25rem',
      fontWeight: 400,
    },
  },
  comment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    background: props => props.background,
    borderRadius: '10px',
    textTransform: 'uppercase',
    color: props => (props.background === '#fff' ? '#000' : '#fff'),
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: '40px',

    '& svg': { marginRight: '20px' },
    '& span': { flex: 1 },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    background: '#fff',
    fontSize: '1rem',
    fontWeight: 400,
    textTransform: 'uppercase',

    '& div': {
      flex: 1,
      padding: '28px 0',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px',
      color: '#fff',
    },
    '& span': { padding: '0 2.5px' },
  },
  cancel: {
    background: '#ff0000',
  },
  confirm: {
    background: '#228844',
  },
});

export default useStyles;
